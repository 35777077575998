import React from "react"
import SEO from "../components/seo"
import { 
  analyticClickEvent
} from "../components/googleAnalytics"

import {
  SectionHeading,
} from "../styledComponents/section"
import HeroBanner from "../components/HeroBanner"
import { HeroText, HeroDescription } from "../styledComponents/heroBanner"
import { StyledAnchorLink } from "../styledComponents/button"
import Icon from "../components/icon"
import { 
  PhonelineSection, 
  PhonelineSectionHeaderInfo, 
  PhonelineSectionHeaderInfoPhone 
} from "../styledComponents/phoneline"
import { StyledBlockQuote } from "../styledComponents/base"
import { Link } from "gatsby"
import LayoutNoStripe from "../components/layoutNoStripe"

const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)

const MindfulnessMeditationPhoneline = ({ data, location }) => {
  return (
    <>
    <LayoutNoStripe location={location} hideNewsletterSignUp>
    <SEO 
          title="Free Mindfulness Meditation Phone Line" 
          description="The UK’s first phone line that you can call to listen to mindful meditations, 24 hours a day."
          url="/mindfulness-meditation-phoneline"
        />
      <HeroBanner
        image="https://hichi-life.com/wp-content/uploads/2020/12/phone-line-lady-on-phone.jpg"
        backgroundPosition="0 0"
        largeBackgroundPosition="0 -260px"
        largeBackgroundSize="100%"
        type="pat"
      >
        <HeroText>
          <HeroDescription>I am delighted to have launched the first Mindfulness Meditation phone line in the UK</HeroDescription>
          <a className="telephone" href="tel:03301201440">
            <Icon name="telephone"></Icon>
            <span>0330 120 1440</span></a>
          <StyledAnchorLink inverse="true" classnameName="learn-more" to="/mindfulness-meditation-phoneline#phone-line">Find out more</StyledAnchorLink>
        </HeroText>
      </HeroBanner>

      <PhonelineSection background="#f1f1f1" sectionTop id="phone-line">
        <PhonelineSectionHeaderInfo>
          <Heading level={2} align="center">In response to the Covid-19 isolation crisis:</Heading>			
          <p>I have created the UK’s first phone line that people can call to listen to mindful meditations, 24 hours a day.</p>
          <p >I have gifted a selection of mindfulness meditations from my App.</p>
        </PhonelineSectionHeaderInfo>
        <PhonelineSectionHeaderInfoPhone>
          <h1 >Please share this number today</h1>
          <h1><a href="tel:03301201440"><Icon name="telephone"></Icon> 0330 120 1440</a></h1>
          
              <ul>
                  <li className="facebook">
                    <a id="share-facebook-button" onClick={() => analyticClickEvent('click',"share",'Minfdulness Meditation Phoneline')} title="Share The UK’s first Mindfulness Meditation phoneline on facebook" href="#" rel="nofollow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 10h-2v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667h1.442v-2.5h-2.404c-1.798 0-2.596.792-2.596 2.308v1.692z"></path></svg><span>Facebook</span></a></li>
                  <li className="whatsapp"><a id="share-whatsapp-button" href="whatsapp://send?text=https://www.karinagrant.co.uk/mindfulness-meditation-phoneline" classname="wa_btn wa_btn_s">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-5.971 18l.844-3.081c-.52-.902-.794-1.925-.794-2.973.001-3.279 2.668-5.946 5.946-5.946 1.591.001 3.084.62 4.206 1.744 1.123 1.124 1.741 2.618 1.74 4.207-.001 3.279-2.669 5.946-5.946 5.946-.995 0-1.976-.25-2.844-.724l-3.152.827zm3.298-1.903c.838.497 1.638.796 2.696.796 2.724 0 4.943-2.217 4.944-4.942.001-2.731-2.208-4.945-4.94-4.946-2.726 0-4.943 2.217-4.944 4.942 0 1.112.326 1.946.873 2.817l-.499 1.824 1.87-.491zm5.694-2.732c-.037-.062-.136-.099-.285-.174-.149-.074-.879-.434-1.015-.483-.136-.05-.235-.074-.334.074-.099.149-.384.483-.47.583-.087.099-.173.112-.322.037-.149-.074-.627-.231-1.195-.737-.442-.394-.74-.881-.827-1.029-.087-.149-.009-.229.065-.303l.223-.26.149-.248c.05-.099.025-.186-.012-.26l-.458-1.103c-.121-.29-.243-.25-.334-.255l-.286-.007c-.099 0-.26.037-.396.186s-.52.508-.52 1.24c0 .731.532 1.438.607 1.537.074.099 1.048 1.6 2.538 2.243.354.153.631.245.847.313.356.113.68.097.936.059.285-.043.879-.359 1.003-.706.123-.348.123-.645.086-.707z"></path></svg><span>WhatsApp</span></a></li>
              </ul>
        </PhonelineSectionHeaderInfoPhone>
      </PhonelineSection>
      <PhonelineSection>
        <h2>Mindfulness &amp; Meditation</h2>
        <p>I believe that mindfulness meditations should be accessible to all.</p>
        <p>I would like to reach people of all ages who do not have access to my App, and who do not have access to smartphones and wellness apps.</p>
        <img src="https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/wp-content/uploads/2020/12/mindfulness-meditation.jpg" className="benefits"/>
        <p>The meditations have been gifted and selected from my <Link to="/product/the-essential-mindfulness-collection/">Essential Mindfulness Collection.</Link></p> 
        <p>The purpose of this initiative is to introduce a mindful practice to <strong>promote a still mind and relaxed body.</strong></p>
        <p>I am proud to have partnered with some amazing charitable organisations for this initiative.</p>
        <StyledBlockQuote>
            <Icon name="singlequote" /><p>Trust for Developing Communities deliver community work with vulnerable people in Brighton &amp; Hove. When lockdown kicked in, our staff were desperate to work harder than ever to meet growing need - but this was so challenging in the environment, with staff isolated and the office closed. People were under real pressure. We made the HI CHI app available to all staff and Karina spoke to us all by Zoom to show us how it worked and gave us tips on bringing more mindfulness to our working days. People used it in different ways to bring some calm and some perspective and this really helped us get through. Enormous thanks to Karina and her team for this really valuable support.</p><cite>Athol Halle - <a href="https://www.trustdevcom.org.uk/" target="_blank">CEO The Trust for Developing Communities</a></cite>
        </StyledBlockQuote>

        <img src="https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/wp-content/uploads/2020/12/hi-chi-phoneline-collage.jpg" />

        <p>By promoting the UK's first Mindful phone line to the people you support we intend to make a difference to people’s quality of mental health.</p>
        <p>Please share this number with an isolated or elderly person that does not have access to my App.</p>

        <p className="stronger-text background">I am dedicated to enhancing people’s lives, one still moment at a time. </p>

        <h2>Some feedback</h2>
          <StyledBlockQuote>
            <Icon name="singlequote" />
							<p>Karina catered wonderfully for such a diverse group.</p>
							<p>I believe this is the first course that I’ve completed that helps the practitioner and takes great care of them, so they can help others. Wonderful.</p>
							<p>Thoroughly enjoyed the experience. Very profound.</p>
							<cite>Rosena Johnston, UK Clinical Lead Physiotherapist, UK Athletics Disability team for Paralympic games</cite>
						</StyledBlockQuote>

						<StyledBlockQuote>
            <Icon name="singlequote" />
							<p>The course was beyond my expectation. Karina is an amazing and inspiring teacher. Extremely enjoyable and easy to digest course.</p>
							<p>A big thank you to Karina and the members of my group.</p>
							<cite>Kiri Stratieva, Care manger in Health and Social Care (dementia), Crossroad Care Hertfordshire North</cite>
						</StyledBlockQuote>

						<StyledBlockQuote>
            <Icon name="singlequote" />
							<p>Brilliant! Once again Karina taught the workshop in a compassionate and wise way.</p>
							<cite>Diane Winfield, UK NHS Occupational Therapist</cite>
						</StyledBlockQuote>

						<StyledBlockQuote>
            <Icon name="singlequote" />
							<p>Karina has a great energy.  Her HI CHI meditations are simple yet very powerful and I use them in my daily practice with my residents in my care home.</p>
							<p>They can also laugh along and enjoy it immensely.</p>
							<p>As a manager of a care home, it is very important to uplift the spirits of the residents.  Since I have started their health has improved mentally and physically.</p>
							<cite>Harpreet K Mann - Care home manager and pharmacist </cite>
						</StyledBlockQuote>

						<StyledBlockQuote>
            <Icon name="singlequote" />
							<p>I some times speak to my friends and others about Karina and it usually starts,My wonderful teacher, Karina Grant, is teaching a workshop, meditation, training tonight and I would not miss it for the world.</p>
							<p>I first experienced Karina teaching about 7 or 8 years ago when I went to one of her courses.</p>
							<p>She is a wonderful teacher. Here presence is so loving and human and you can feel the knowledge and skill changing the atmosphere in the room to one of love and peace.</p>
							<p>Her meditations are beautiful and sweet and deep, filled with peace and hope.</p>
							<p>She is clear and gentle as a teacher and has great understanding and skill.</p>
							<p>Wonderful teacher , wonderful person. You could not do better.</p>
							<cite>Lisa Jennings, former NHS Psychotherapist now working in private practice London</cite>
						</StyledBlockQuote>

						<h2>Charities</h2>
						<p>If you are a charity and would like to include this wellness phoneline in your offering please do <a href="/contact">get in contact</a>.</p>
						<p>If you are a care home and would like to be sent a login for the app mindfulness collection please do <a href="/contact">get in contact</a>. Many care homes are playing our meditations to their residents, and they are enjoying them immensely. </p>
						<p>Science has demonstrated that Mindfulness increases resilience, reduces pain and dramatically improves mental health and wellbeing. </p>

						<h2>Frequently asked questions</h2>
						<h3>Are the mindfulness meditations free?</h3>
						<p>The meditations are selected from Karina's mindfulness collection and are freely available to the isolated and elderly.</p>
						<h3>Is each meditation exactly the same as on the App?</h3>
						<p>All the meditations on the app have been recorded in a professional studio so the sound quality is excellent. Due to the technical limitations of a phoneline compared to an app, the sound quality on the phoneline is lower than listening online or an app. I have removed the music from the meditations on the phoneline so that the vocals can be heard clearly enough to be accessible to all.</p>
						<h3>Is 0330 a freephone number?</h3>
						<p>The cost to call a 0330 number is the same as a landline. This means that if you are calling from a home landline, it will be charged at the same rate as another local number. Many phone companies include free minutes to local phone numbers at specific times such as evenings and weekends. If you are calling from a mobile phone, the call will be charged at the same rate as any other call to a UK landline. Many mobile phone providers include free minutes in their monthly bundles, so your call will be taken from your allocation of minutes.</p>
						<p>Please check call prices with your phone operator if you are on a pay as you go contract.</p>
			</PhonelineSection>
    </LayoutNoStripe>
    </>
  )
}

export default MindfulnessMeditationPhoneline