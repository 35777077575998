import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base";
import { StyledTabsList, StyledTab, StyledTabSelected } from "../styledComponents/tabs"
import { bold28, bold32, regular14, regular16 } from "./typography";

const PhonelineSection = styled.section`
  ${baseGridStyles}
  background: ${props => props.background ? props.background : 'transparent'};
  @media screen and (min-width: ${breakpoints.l}px) {
    max-width: ${props => props.background ? '100%' : 'auto'};
    ${props => props.sectionTop && 'padding: 8rem 0'};
    h2 {
      ${props => props.sectionTop && 'margin-bottom: 2rem'};
    }
  }

  

  h2, h3, p{
    grid-column: span 4;
    @media screen and (min-width: ${breakpoints.sm}px) {
      grid-column: span 6;
    } 
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: span 8;
    }
  }

  h2, h3 {
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: span 12;
      text-align: left;
      width: 100%;
    } 
  }
  

  blockquote  {
    grid-column: span 4;
    @media screen and (min-width: ${breakpoints.sm}px) {
      grid-column: span 4;
    } 
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: span 6;
    } 
  }
  section {
    padding: 0;
  }
  p{
    margin: 0;
  }
  img {
    width: 100%;
    grid-column: span 4;
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: span 6;
    } 

    &:nth-of-type(2){
      margin-top: 2rem;
    }
  }
  svg {
    width: 2.4rem;
    height: 2.4rem;
  }
  div.share-phone-number {
    background: var(--light-purple-darker);
  }
`
const PhonelineSectionHeaderInfo = styled.div`
h2 {
  text-align: left;
  margin-bottom: 0.8rem;
}
  text-align: left;
  grid-column: span 4;
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: span 3;
    text-align: left;
    display: flex;
    justify-content: center;
    flex-direction: column;
  } 
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: span 6;
  } 
  @media screen and (min-width: ${breakpoints.l}px) {
    grid-column: 3 / 8;
  } 
  
  p {
    margin-bottom: 0.8rem;
    color: var(--light-purple-darker);
    font-weight: 700;
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: span 6;
    }
  
  }

  
`

const PhonelineSectionHeaderInfoPhone = styled.div`
  background: var(--light-purple-darker);
  border-radius: 0.4rem;
  padding: 1.4rem;
  text-align: center;
  grid-column: span 4;
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: span 3;
  } 
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: span 6;
  } 
  @media screen and (min-width: ${breakpoints.l}px) {
    grid-column: 8 / 11;
  } 

  h1, a {
    color: #fff;
    border-bottom: 0;
    
    @media screen and (min-width: ${breakpoints.md}px) {
      ${bold32}
    }
  }

  ul { 
    margin: 0.8rem 0 0;
    list-style-type: none;
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    @media screen and (min-width: ${breakpoints.sm}px) {
      
    }
    li {
        border: 1px solid #ffffee85;
        border-radius: 4px;
        display: inline;
        flex-direction: column;
        margin-right: 1rem;
        margin-bottom: 0 !important;
        width: 100%;
        padding: 0.8rem;
        @media screen and (min-width: ${breakpoints.sm}px) {
          width: 50%;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
          padding: 0;
        }
        &.facebook {
          background: #3b5998;
          font-weight: 500;
        }
        &.whatsapp {
          background: green;
          margin-right: 0;
        }
        a {
          font-size: 1.5rem;
          color: #fff;
          padding: 0.4rem;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          border-bottom: 0;
        }
        svg {
          fill: #fff;
          margin-right: 0.8rem;
        }
      }
  }

`

export { 
  PhonelineSection,
  PhonelineSectionHeaderInfo,
  PhonelineSectionHeaderInfoPhone
}